import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';
import { colors } from '../styles/colors';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { Author, NoImage, PostFull } from '../templates/post';
import styled from '@emotion/styled';
import config from '../config/website-config';
import { graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import RehypeReact from 'rehype-react';

const Ast = ({ ast, ...props }: any) => {
  ast.properties = props;
  return renderAst(ast);
};

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {},
}).Compiler;

interface AboutTemplateProps {
  data:{
    allMarkdownRemark: {
      totalCount: number;
      edges: Array<{
        node: AboutPageContext;
      }>;
    };
  }
}

export interface AboutPageContext {
  excerpt: string;
  fields: {
    slug: string;
    readingTime: {
      text: string;
    };
  };
  htmlAst: any;
  frontmatter: {
    image: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    excerpt: string;
    title: string;
    date: string;
    draft?: boolean;
    type: string;
    tags: string[];
    author: Author[];
  };
}



const About: React.FC<AboutTemplateProps> = props => {
  let content = props.data.allMarkdownRemark.edges[0].node;
  
  return(
    <IndexLayout>
      <Helmet>
        <html lang={config.lang} />
        <title>About - {config.title} </title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
          <div css={[outer, SiteNavMain]}>
            <div css={inner}>
              <SiteNav isHome={false} />
            </div>
          </div>
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <article className="post page" css={[PostFull, NoImage]}>
              <HeaderFitContent className="header-fit-content">
                <TitleFitContent className="title-fit-content">{content.frontmatter.title}</TitleFitContent>
                <h3>{content.frontmatter.excerpt}</h3>
              </HeaderFitContent>

              <PostFullContent className="post-full-content">
                <Ast className="post-content" ast={content.htmlAst} />
              </PostFullContent>
            </article>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default About;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {frontmatter: {draft: {ne: true}, type: {eq: "about"}}}
      limit: 1
    ) {
      edges {
        node {
          htmlAst
          frontmatter {
            title
            date
            tags
            draft
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author {
              id
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    fluid(quality: 100, srcSetBreakpoints: [40, 80, 120]) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          excerpt
          fields {
            readingTime {
              text
            }
            layout
            slug
          }
        }
      }
    }
  }
`;

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  /* @media (prefers-color-scheme: dark) {
    .site-main {
      background: var(--darkmode);
      background: ${colors.darkmode};
    }
  } */
`;

export const HeaderFitContent = styled.header`
  position: relative;
  margin: 0 auto;
  padding: 70px 170px 50px;
  /*border-top-left-radius: 3px;
  border-top-right-radius: 3px;*/

  @media (max-width: 1170px) {
    padding: 60px 11vw 50px;
  }

  @media (max-width: 800px) {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  @media (max-width: 500px) {
    padding: 20px 0 35px;
  }

  h3{
    text-align: center;
    color: ${colors.darkblue};
  }
`;

export const TitleFitContent = styled.h1`
  font-family: Krub;
  margin: 0 0 0.2em;
  color: ${colors.redbrown};
  text-align: center;

  @media (max-width: 500px) {
    margin-top: 0.2em;
    font-size: 3.3rem;
  }
`;
